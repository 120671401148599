import React from 'react';
import { useMedia } from 'react-use';

import { Theme } from '@silvertours/front-shared';
import { Suppliers as SuppliersView } from '@silvertours/common-landingpages-view';
import { LayoutLegacy } from '@silvertours/front-legacy-entities';

import {
  PrimaryTitle,
  PrimaryWrapper,
  SupplierList,
  SupplierListItem,
  SupplierWrapper,
  StyledThirdPartyLogo,
} from './Suppliers.styles';
import { TrustSeals } from '../TrustSeals';

type SuppliersProps = {
  title?: string;
  suppliers?: SuppliersView;
  includeSearch?: boolean;
  includeTrustSeals?: boolean;
  isMinimal?: boolean;
  isPlain?: boolean;
};

const Suppliers = ({
  title,
  suppliers,
  includeSearch = true,
  includeTrustSeals = true,
  isMinimal = false,
  isPlain = false,
}: SuppliersProps) => {
  const isMobile = useMedia(
    `(max-width: ${Theme.getBreakpoint('sm')}px)`,
    true,
  );

  if (!title && (!suppliers || suppliers.length === 0)) {
    return null;
  }

  return (
    <LayoutLegacy.FilledSegment id="suppliers">
      {includeTrustSeals && (
        <TrustSeals includeSearch={includeSearch} isPlainTemplate={isPlain} />
      )}
      {(((!isMobile || isMinimal) && title) ||
        (suppliers && includeSearch)) && (
        <SupplierWrapper includeSearch={includeSearch} isMinimal={isMinimal}>
          {title && (
            <PrimaryWrapper isMinimal={isMinimal}>
              {(!isMobile || isMinimal) && <PrimaryTitle>{title}</PrimaryTitle>}
            </PrimaryWrapper>
          )}
          {suppliers && includeSearch && (
            <SupplierList isStandAlone={!title}>
              {suppliers.map(({ id }) => (
                <SupplierListItem key={id}>
                  <StyledThirdPartyLogo
                    name={id}
                    withPadding="none"
                    grayScale
                  />
                </SupplierListItem>
              ))}
            </SupplierList>
          )}
        </SupplierWrapper>
      )}
    </LayoutLegacy.FilledSegment>
  );
};

export { Suppliers };
export type { SuppliersProps };
