import React, { useState, useMemo, useCallback } from 'react';
import { useTranslations } from 'next-intl';
import { Stage as StageData } from '@silvertours/common-landingpages-view';
import { HostLegacy, LayoutLegacy } from '@silvertours/front-legacy-entities';
import { MediaLegacy, Theme } from '@silvertours/front-legacy-shared';
import { useMedia } from 'react-use';
import { SearchForm, SearchProvider } from '../SearchFormLegacy';
import {
  StageContentContainer,
  StageCaption,
  StageLink,
  StageWrapper,
  StyledH1,
  WizardSearchWrapper,
} from './Stage.styles';
import { StageContext } from './context';
import { Error } from './Error';
import { SearchContainer } from './SearchContainer';

type StageProps = {
  content?: StageData;
  // TODO: Provide link from BFF
  link?: {
    href: string;
    target: string;
    title: string;
  };
  statusCode?: number;
  headline?: string;
  isMinimal?: boolean;
  isPlain?: boolean;
};

const IMAGE_PATH_PREFIX = '/bm/global/files/images/';

const Stage = ({
  content,
  link,
  statusCode,
  headline,
  isMinimal,
  isPlain,
}: StageProps) => {
  const isMobile = useMedia(
    `(max-width: ${Theme.getBreakpoint('sm')}px)`,
    true,
  );

  const {
    keyword,
    search,
    image: imageData,
    includeSearch,
    imageWithFocalPoint,
  } = content || {};

  const t = useTranslations('features.stageLegacy.stage');

  const { formatImage } = HostLegacy.useHost();

  const defaultImage: MediaLegacy.ImageProps = {
    src: t('defaultImage.src'),
    alt: t('defaultImage.alt'),
    id: t('defaultImage.id'),
  };

  const image = imageWithFocalPoint || imageData || defaultImage;

  const [stageImage, setStageImage] = useState(image.src);

  const handleStageImageChange = useCallback(
    (src?: string) => {
      const imageSrc = src ? `${IMAGE_PATH_PREFIX}${src}` : defaultImage.src;
      setStageImage(imageSrc);
    },
    [defaultImage.src],
  );

  const value = useMemo(
    () => ({
      stageImage,
      setStageImage: handleStageImageChange,
    }),
    [handleStageImageChange, stageImage],
  );

  const newImage = { ...image };

  if (image === defaultImage) {
    newImage.src = formatImage(stageImage || image.src);
  }

  return (
    <StageWrapper isMinimal={isMinimal}>
      <LayoutLegacy.ImageSegment
        id="stage-image"
        image={newImage}
        isStage
        includeSearch={includeSearch}
        isMinimal={isMinimal}
        isPlain={isPlain}
      >
        <StageContentContainer isMinimal={isMinimal}>
          {isMobile && !isMinimal && (
            <WizardSearchWrapper>
              <StyledH1>{headline}</StyledH1>
              <SearchProvider pageData={search}>
                <SearchContainer
                  image={newImage}
                  includeSearch={includeSearch}
                />
              </SearchProvider>
              <Error statusCode={statusCode} />
            </WizardSearchWrapper>
          )}
          {!isMobile && (
            <StageContext.Provider value={value}>
              {Boolean(link?.href) && (
                <StageLink href={link?.href} target={link?.target}>
                  {link?.title}
                </StageLink>
              )}
              {includeSearch && (
                <StageCaption>
                  <SearchForm stageTitle={keyword} pageData={search} />
                </StageCaption>
              )}
              <Error statusCode={statusCode} />
            </StageContext.Provider>
          )}
        </StageContentContainer>
      </LayoutLegacy.ImageSegment>
    </StageWrapper>
  );
};

export { Stage };
export type { StageProps };
